import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { ContactUsComponent } from './shared/components/contact-us/contact-us.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './shared/components/privacy-policy/privacy-policy.component';
import { TermsComponent } from './shared/components/terms/terms.component';

const routes: Routes = [
  {
    path: 'secure',
    loadChildren: () =>
      import('../app/secure/secure.module').then((m) => m.SecureModule),
    canActivate: [AuthGuard],
  },
  { path: '', pathMatch: 'full', redirectTo: 'secure' },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: '**', pathMatch: 'full', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
