export const RouterUrlType = {
  DASHBOARD: '/secure/dashboard/landing',
  FUNDING_PIPELINE: '/secure/funding/funding-pipeline',
  FUNDING_LOAN_REQUEST: '/secure/funding/loan-request',
  FUNDING_LOAN_VIEW: '/secure/funding/loan-request/',
  SETTLEMENT: '/secure/settlement/',
  COLLATERAL: '/secure/collateral/',
  ADMIN_ORIGINATOR_PROFILE: '/secure/admin/originator-profile',
  ADMIN_GENERAL_SETUP: '/secure/admin/general-setup',
  ADMIN_INTEREST_ACCURAL: '/secure/admin/interest-accural',
  ADMIN_FINANCING_SOURCE: '/secure/admin/financing-sources',
  TREASURY: '/secure/treasury',
  ADMIN_WIRE_RECIPIENT_SETUP: '/secure/admin/wire-recipient-setup/',
  ADMIN_WIRE_RECIPIENT_PROFILE: '/secure/admin/wire-recipient-summary',
  ADMIN_SHIPPING: '/secure/admin/shipping',
  ADMIN_WIRE_RECIPIENT_VERIFICATION:
    '/secure/admin/wire-recipient-verification',
  ADMIN_CONDITIONS: '/secure/admin/conditions',
  SUPER_ADMIN_TENANTS: '/secure/admin/tenant-summary',
  SETTLEMENT_LANDING: '/secure/settlement/settlement-landing',
  ORIGINATORS_SUMMARY: '/secure/admin/originators-summary',
  INDEX_SETUP: '/secure/admin/index-setup',
  INVESTOR_SUMMARY: '/secure/admin/investor-summary',
};
