import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoanSearchService {
  private _fortaLoanId$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  private _displayLoanId$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  constructor() {}

  getFortaLoanId(): BehaviorSubject<string> {
    return this._fortaLoanId$;
  }

  setFortaLoanId(loanId: string): void {
    this._fortaLoanId$.next(loanId);
  }

  getDisplayLoanId(): BehaviorSubject<string> {
    return this._displayLoanId$;
  }

  setDisplayLoanId(loanId: string): void {
    this._displayLoanId$.next(loanId);
  }
}
