import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomEvent } from 'src/app/shared/models/custom-event.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomEventsService {
  private _event$: BehaviorSubject<CustomEvent> =
    new BehaviorSubject<CustomEvent>({});
  private _isSubmitted$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  private _isFormValid$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor() {}

  getEvent(): BehaviorSubject<CustomEvent> {
    return this._event$;
  }

  setEvent(event: CustomEvent): void {
    this._event$.next(event);
  }

  setSubmittedValue(value: any): void {
    this._isSubmitted$.next(value);
  }

  getSubmittedValue() {
    return this._isSubmitted$;
  }

  setFormValidity(value: boolean): void {
    this._isFormValid$.next(value);
  }

  getFormValidity() {
    return this._isFormValid$;
  }
}
