import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit, OnDestroy {
  currentYear = new Date().getFullYear();
  subscription: Subscription = new Subscription();
  contactUsMail!: string;
  constructor(
    private i18nService: I18nService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    let i18nServiceSubscription = this.i18nService
      .getLanguage()
      .subscribe((language: string) => {
        this.translate.use(language);
      });

    this.subscription.add(i18nServiceSubscription);
    this.contactUsMail = environment.contactUsMail;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
