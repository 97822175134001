import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { ApiCallsService } from 'src/app/shared/services/api-calls/api-calls.service';

import { CustomEventTokens } from 'src/app/shared/models/custom-event-tokens';
import { User } from 'src/app/shared/models/user.interface';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    constructor( 
        @Inject(DOCUMENT) private document: Document,
        private localStorageService: LocalStorageService,
        private apiCallsService: ApiCallsService
    ) {}

    changeTheme(user: User) {
        let theme = this.renderTheme(user)
        this.saveTheme(theme);
    }

    renderTheme(user: User): string {
        if(user.authentication) {
            this.localStorageService.createLocalStorageItem(CustomEventTokens.AGILITY_USER, user);
        }

        let themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;
        let theme = (user.theme) ? user.theme : 'default';
        if (themeLink) {
            themeLink.href = 'assets/css/themes/' + theme + '.css';
        }

        return theme;
    }

    saveTheme(theme: string) {
        /* Need to remove the Subscribe part later */
        this.apiCallsService.saveTheme(theme).subscribe({
            next: (data) => console.log('Theme -----> ' + data)
        });
    }
}