import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
})
export class MobileNavigationComponent implements OnInit {
  @Input('show-mobile-nav') public turnOnMobileAppNavigation = true;
  constructor() {}

  ngOnInit(): void {}

  onMobileAppNavShow(): void {
    (document.querySelector('#mobileAppNav a') as HTMLButtonElement)?.focus();
  }

  onMobileAppNavHide(): void {
    (
      document.querySelector('#hamBurgerMenuIcon') as HTMLButtonElement
    )?.focus();
  }
}
