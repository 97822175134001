import { BehaviorSubject } from 'rxjs';

export class ClosingAgentService {
  private _closingAgentId$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  private _secureIsActive$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private _accounts$: BehaviorSubject<Array<any>> = new BehaviorSubject<
    Array<any>
  >([]);
  constructor() {}

  getClosingAgentId(): BehaviorSubject<string> {
    return this._closingAgentId$;
  }

  setClosingAgentId(closingAgentId: string): void {
    this._closingAgentId$.next(closingAgentId);
  }

  setSecureIsActive(secureIsActive: boolean): void {
    this._secureIsActive$.next(secureIsActive);
  }

  getSecureIsActive(): BehaviorSubject<boolean> {
    return this._secureIsActive$;
  }

  setAccounts(accounts: Array<any>) {
    this._accounts$.next(accounts);
  }

  getAccounts(): BehaviorSubject<Array<any>> {
    return this._accounts$;
  }
}
