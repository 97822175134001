<div
  class="loan-card p-card p-2 mt-0 mb-2 border-noround relative"
  [ngClass]="[
    loan?.selected
      ? 'border-2 border-primary bg-slate-50'
      : 'border-2 border-white bg-surface-0',
    loan?.status === 'New' && loan?.isNewlyCreated
      ? 'bg-success-light border-success-light'
      : ''
  ]"
>
  <div
    *ngIf="loan?.status === 'Pending Approval'"
    class="border-left-2 h-full w-0 absolute left-0 top-0 color-danger ml-2-px"
  ></div>
  <div
    *ngIf="loan?.status === 'Processing'"
    class="border-left-2 h-full w-0 absolute left-0 top-0 color-success ml-1-px"
  ></div>
  <div class="flex justify-content-between align-items-center">
    <ng-container *ngIf="dataLoading">
      <p-skeleton shape="circle" size="1.5rem"></p-skeleton>
    </ng-container>

    <ng-container *ngIf="dataLoading">
      <p-skeleton width="8.5rem" height="1.75rem"></p-skeleton>
    </ng-container>
    <ng-container *ngIf="dataSuccess">
      <a
        [routerLink]="['/secure/funding/loan-request', loan.fortaLoanId]"
        class="loanNumber text-xl flex-1 white-space-nowrap overflow-hidden text-overflow-ellipsis color-surface-900 cursor-pointer"
      >
        {{ loan?.displayLoanId }}
      </a>
    </ng-container>

    <ng-container *ngIf="dataLoading">
      <p-skeleton width="5rem" height="1.75rem"></p-skeleton>
    </ng-container>
    <ng-container *ngIf="dataSuccess">
      <span class="wireAmount text-xl">{{
        loan?.loanDetail?.wireAmount | currency
      }}</span>
    </ng-container>
  </div>
  <div
    class="flex justify-content-between text-sm align-items-center my-2 color-surface-700"
  >
    <ng-container *ngIf="dataLoading">
      <p-skeleton width="7rem"></p-skeleton>
    </ng-container>
    <ng-container *ngIf="dataSuccess">
      <div class="borrower-date">
        <span title="{{ borrowerName }}">{{ borrowerName }}</span>
        <span class="mx-1">|</span>
        <span>{{
          loan?.loanDetail?.requestedWireDate | date : 'MM/dd/yy'
        }}</span>
      </div>
    </ng-container>

    <ng-container *ngIf="dataLoading">
      <p-skeleton width="5rem"></p-skeleton>
    </ng-container>
    <ng-container *ngIf="dataSuccess">
      <span class="imb-name">{{
        loan?.originator?.originatorName | slice : 0 : 10
      }}</span>
    </ng-container>
  </div>
  <div
    class="flex justify-content-between flex-row-reverse text-xs align-items-center h-75"
  >
    <ng-container *ngIf="dataLoading">
      <p-skeleton width="7rem"></p-skeleton>
    </ng-container>
    <ng-container *ngIf="dataSuccess">
      <span class="loan-status flex" [ngClass]="statusClass"
        >{{ loan?.status | uppercase }}
        <span class="ml-0">
          <span *ngIf="hardConditionCount" class="relative left-5">
            <p-badge
              value="{{ hardConditionCount }}"
              severity="danger"
              styleClass="color-surface-200 border-1"
            ></p-badge>
          </span>
          <p-badge
            *ngIf="softConditionCount"
            value="{{ softConditionCount }}"
            severity="warning"
            styleClass="color-surface-200 border-1"
          ></p-badge>
        </span>
      </span>
    </ng-container>

    <ng-container *ngIf="dataLoading">
      <p-skeleton width="3rem"></p-skeleton>
    </ng-container>
    <ng-container *ngIf="dataSuccess">
      <div class="flex-1 ml-1 text-white">
        <span
          *ngIf="loan?.loanDetail?.highPriorityIndicator"
          class="high-priority w-1rem h-1rem border-circle bg-danger flex justify-content-center align-items-center p-1"
          >!</span
        >
      </div>
      <span
        *ngIf="loan?.collateral?.wet"
        class="wet-loan ng-star-inserted bg-primary text-white px-2 py-1 border-round-xl line-height-1"
        >WET</span
      >
      <span
        *ngIf="!loan?.collateral?.wet"
        class="dry-loan ng-star-inserted bg-slate-100 text-primary px-2 py-1 border-round-xl line-height-1"
        >DRY</span
      >
    </ng-container>
  </div>
</div>
