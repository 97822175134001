import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class FundingDashboardService {
  private selectedIMB: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  constructor() {}
  setselectedIMB(imb: string) {
    this.selectedIMB.next(imb);
  }
  getselectedIMB(): BehaviorSubject<string> {
    return this.selectedIMB;
  }
}
