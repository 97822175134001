import { Injectable } from '@angular/core';

import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { ApiCallsService } from 'src/app/shared/services/api-calls/api-calls.service';
import { User } from 'src/app/shared/models/user.interface';
import { CustomEventTokens } from 'src/app/shared/models/custom-event-tokens';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  private localStorageUser: User = this.localStorageService.getLocalStorageItem(CustomEventTokens.AGILITY_USER) as User; 
  private _language: string = ( this.localStorageUser && this.localStorageUser.language ) ? this.localStorageUser.language : CustomEventTokens.ENGLISH_LANGUAGE;
  private _language$: BehaviorSubject<string> = new BehaviorSubject<string>(this._language);

  constructor( 
    private localStorageService: LocalStorageService,
    private apiCallsService: ApiCallsService
  ) {}

  getLanguage(): BehaviorSubject<string> {
    return this._language$;
  }

  setLanguage(language: string): void {
    this._language$.next(language);
  }

  saveLanguage(language: string) {
      /* Need to remove the Subscribe part later */
      this.apiCallsService.saveLanguage(language).subscribe({
          next: (data) => console.log('Preferred Language -----> ' + data)
      });
  }
}
