import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  createLocalStorageItem(name: string, value: unknown): void {
    localStorage.setItem(name, JSON.stringify(value));
  }

  deleteLocalStorageItem(name: string): void {
    localStorage.removeItem(name);
  }

  getLocalStorageItem(name: string): any {
    return JSON.parse(localStorage.getItem(name) || '{}');
  }

  isShowLedgerDetails(): boolean {
    return true;
  }
}
