import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomEventTokens } from '../../models/custom-event-tokens';
import {
  EnumerationData,
  IDocument,
  IState,
  LabelValuePair,
} from '../../models/enumeration-types.interface';
import { LocalStorageService } from '../local-storage/local-storage.service';

export type EnumerationServiceData = {
  borrowerTypes: ['Individual', 'Business', 'Trust'];
  amortizationTypes: string[];
  appraisalTypes: string[];
  citizenShips: string[];
  documentationTypes: string[];
  documentTypes: LabelValuePair[];
  dashboardItems: string[];
  lienPositions: string[];
  occupancies: string[];
  productCodes: string[];
  productNames: LabelValuePair[];
  propertyTypes: string[];
  refinanceTypes: string[];
  states: LabelValuePair[];
  transactionTypes: string[];
};

@Injectable({
  providedIn: 'root',
})
export class EnumerationsService {
  borrowerTypes: EnumerationServiceData['borrowerTypes'] = [
    'Individual',
    'Business',
    'Trust',
  ];
  amortizationTypes: EnumerationServiceData['amortizationTypes'] = [];
  appraisalTypes: EnumerationServiceData['appraisalTypes'] = [];
  citizenShips: EnumerationServiceData['citizenShips'] = [];
  documentationTypes: EnumerationServiceData['documentationTypes'] = [];
  documentTypes: EnumerationServiceData['documentTypes'] = [];
  dashboardItems: EnumerationServiceData['dashboardItems'] = [];
  lienPositions: EnumerationServiceData['lienPositions'] = [];
  occupancies: EnumerationServiceData['occupancies'] = [];
  productCodes: EnumerationServiceData['productCodes'] = [];
  productNames: EnumerationServiceData['productNames'] = [];
  propertyTypes: EnumerationServiceData['propertyTypes'] = [];
  refinanceTypes: EnumerationServiceData['refinanceTypes'] = [];
  states: EnumerationServiceData['states'] = [];
  transactionTypes: EnumerationServiceData['transactionTypes'] = [];

  private enumerationsSubject: BehaviorSubject<EnumerationServiceData> =
    new BehaviorSubject<EnumerationServiceData>({
      states: [],
      transactionTypes: [],
      productCodes: [],
      refinanceTypes: [],
      documentationTypes: [],
      amortizationTypes: [],
      lienPositions: [],
      citizenShips: [],
      propertyTypes: [],
      occupancies: [],
      appraisalTypes: [],
      documentTypes: [],
      productNames: [],
      dashboardItems: [],
      borrowerTypes: this.borrowerTypes,
    });
  enumerations$: Observable<EnumerationServiceData> =
    this.enumerationsSubject.asObservable();

  constructor(private localStorageService: LocalStorageService) {}

  updateEnumerations() {
    const enumerations = this.localStorageService.getLocalStorageItem(
      CustomEventTokens.AGILITY_USER
    )?.enumerations;
    this.initialize(enumerations);
  }

  initialize(enumerations: Partial<EnumerationData>) {
    if (!enumerations) {
      return;
    }

    const fundingDocument = enumerations.masterDocuments?.find(
      (document: IDocument) => document.documentType === 'Funding'
    );

    this.states =
      (enumerations.states || []).map(({ stateName, stateCode }: IState) => ({
        label: stateName,
        value: stateCode,
      })) || [];
    this.transactionTypes = enumerations.transactionTypes || [];
    this.productCodes = enumerations.productCodes || [];
    this.refinanceTypes = enumerations.refinanceTypes || [];
    this.documentationTypes = enumerations.documentationTypes || [];
    this.amortizationTypes = enumerations.amortizationTypes || [];
    this.lienPositions = enumerations.lienPositions || [];
    this.citizenShips = enumerations.citizenShips || [];
    this.propertyTypes = enumerations.propertyTypes || [];
    this.occupancies = enumerations.occupancies || [];
    this.appraisalTypes = enumerations.appraisalTypes || [];
    this.documentTypes =
      (fundingDocument?.documentSubTypes || []).map((docSubType) => ({
        label: docSubType,
        value: docSubType,
      })) || [];
    this.productNames =
      (enumerations.productNames || []).map((productName) => ({
        label: productName,
        value: productName,
      })) || [];
    this.dashboardItems = enumerations.dashboardItems || [];

    this.enumerationsSubject.next({
      states: this.states,
      transactionTypes: this.transactionTypes,
      productCodes: this.productCodes,
      refinanceTypes: this.refinanceTypes,
      documentationTypes: this.documentationTypes,
      amortizationTypes: this.amortizationTypes,
      lienPositions: this.lienPositions,
      citizenShips: this.citizenShips,
      propertyTypes: this.propertyTypes,
      occupancies: this.occupancies,
      appraisalTypes: this.appraisalTypes,
      documentTypes: this.documentTypes,
      productNames: this.productNames,
      dashboardItems: this.dashboardItems,
      borrowerTypes: this.borrowerTypes,
    });
  }
}
