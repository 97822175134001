<section
  class="w-full page-layout"
  [class.secure]="isAuthenticated"
  [class.public]="!isAuthenticated"
  [class.banner]="isBannerMessage"
>
  <div *ngIf="!isAuthenticated">
    <router-outlet></router-outlet>
  </div>
  <div *ngIf="isAuthenticated" class="grid m-0">
    <article class="col-12 page-header p-0 fixed z-5">
      <div id="messageBanner">
        <p-toast (onClose)="onCloseBannerMessage()" key="toast1"></p-toast>
      </div>
      <div class="px-4"><app-header *ngIf="isAuthenticated"></app-header></div>
    </article>
    <main class="col-12 page-content p-0 z-3">
      <div class="w-full">
        <div
          class="h-screen fixed sidebar-container"
          [ngClass]="isCloseSidebar ? '' : ''"
          [id]="isCloseSidebar ? '' : 'sidebarMenu'"
        >
          <app-sidebar
            (closeSidebarEvent)="closeSidebar($event)"
            [isHideSidebar]="isCloseSidebar"
            *ngIf="isAuthenticated"
          ></app-sidebar>
        </div>
        <div
          *ngIf="_showPermissionsBanner"
          class="text-white surface-900 flex align-items-center"
          [ngClass]="
            isCloseSidebar ? 'side-panel-collapsed' : 'side-panel-expanded'
          "
          id="permissions-banner"
        >
          <div class="flex-1 flex align-items-center justify-content-center">
            <i class="pi pi-lock text-xs mx-3"></i>
            <span class="content text-xs">{{ permissionService.banner }}</span>
            <i class="pi pi-lock text-xs mx-3"></i>
          </div>
          <i
            class="pi pi-times text-xs mx-3 cursor-pointer"
            (click)="onClosePermissionsBanner()"
          ></i>
        </div>
        <div
          style="min-height: calc(100vh - 80px)"
          [ngClass]="isCloseSidebar ? 'sidebar-close' : 'sidebar-open'"
          [class.funding-pipeline]="isFundingPipelinePage"
          [class.reporting]="isReportingPage"
          id="page-content"
        >
          <div class="md:pb-5 z-2" [class.pt-3]="_showPermissionsBanner">
            <router-outlet></router-outlet>
            <article
              class="col-12 page-footer pt-1 pb-4 pl-3 pr-4 absolute bottom-0 w-full h-1rem mb-1"
            >
              <app-footer></app-footer>
            </article>
          </div>
        </div>
      </div>
    </main>
  </div>
</section>
<p-toast [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column" style="flex: 1">
      <div class="flex-align">
        <i class="pi p-1" style="font-size: 2rem" [class]="message.icon"></i>
        <div class="flex flex-1 flex-column px-3">
          <h4 class="m-1">{{ message.summary }}</h4>
          <p class="m-1" [innerHTML]="message.detail"></p>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
<p-confirmDialog rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
<p-confirmDialog
  [style]="{ width: '29.25rem' }"
  key="inactivityDialog"
  (onHide)="onHideInactivityDialog()"
></p-confirmDialog>
