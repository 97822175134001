<div style="min-width: 300px">
  <h3 class="color-slate-900 text-xl m-0">Loan Search</h3>

  <div class="p-inputgroup w-full flex flex-wrap pt-2 mb-2">
    <span class="p-input-icon-left flex-1">
      <i class="pi pi-search"></i>
      <input
        pInputText
        [(ngModel)]="searchId"
        placeholder="{{ 'loanSearch.enterLoan' | translate }}..."
        mode="decimal"
        id="search-loan"
        inputStyleClass="pl-5"
        class="w-full h-full"
        (keyup.enter)="onEnteringLoanNumber()"
      />
    </span>
    <p-button styleClass="p-button-sm ml-1" (onClick)="onEnteringLoanNumber()">
      {{ 'loanSearch.go' | translate }}</p-button
    >
  </div>

  <div>
    <div *ngFor="let loan of searchedLoans">
      <app-global-loan-card
        class="mt-2"
        [loan]="loan"
        [data-success]="true"
      ></app-global-loan-card>
    </div>
    <div
      class="color-surface-500 my-2 text-xs"
      *ngIf="loans.length > 0 || loadingCards.length > 0"
    >
      {{ 'loanSearch.recentlyViewedLoans' | translate }}
    </div>
    <div
      *ngIf="showNotFoundMessage"
      class="my-2 text-xs text-center my-5 color-danger"
    >
      {{ 'loanSearch.noResults' | translate }}
    </div>
    <ng-container *ngFor="let loan of loans">
      <app-global-loan-card
        [loan]="loan"
        [data-success]="true"
      ></app-global-loan-card>
    </ng-container>
    <ng-container *ngFor="let loan of loadingCards">
      <app-global-loan-card [data-loading]="true"></app-global-loan-card>
    </ng-container>
  </div>
</div>
