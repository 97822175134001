import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { GraphqlApisService } from '../../services/graphql-apis/graphql-apis.service';
import { Subscription } from 'rxjs';
import { ToastMessageService } from '../../services/toast-message.service';
import { LoaderService } from '../../services/loader/loader.service';
import { NavigationStart, Router } from '@angular/router';
import { ILoan, Loan } from '../../models/loan.interface';

@Component({
  selector: 'app-global-loan-search',
  templateUrl: './global-loan-search.component.html',
})
export class GlobalLoanSearchComponent implements OnInit, OnDestroy {
  searchId!: string;
  subscription: Subscription = new Subscription();
  loans: any[] = [];
  loadingCards: any[] = [];
  @Input()
  recentlyViewedLoanSize: number = 4;
  @Output()
  closeOverlayPanel = new EventEmitter<Boolean>();
  showNotFoundMessage: boolean = false;
  searchedLoans: ILoan[] = [];

  constructor(
    private graphqlApisService: GraphqlApisService,
    private toastMessageService: ToastMessageService,
    private loaderService: LoaderService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.closeOverlayPanel.emit(true);
      }
    });
    this.loadingCards = this.loaderService.createLoadingRows(4);
    const getRecentlyViewedLoansSubscription = this.graphqlApisService
      .getLoginUserViewedLoanIds(this.recentlyViewedLoanSize)
      .subscribe((data: any) => {
        if (data?.data?.getLoginUserViewedLoanIds) {
          this.loans = data.data.getLoginUserViewedLoanIds;
        }
        this.loadingCards = [];
      });
    this.subscription.add(getRecentlyViewedLoansSubscription);
  }

  onEnteringLoanNumber() {
    if (this.searchId != '') {
      this.showNotFoundMessage = false;
      this.loadingCards = this.loaderService.createLoadingRows(5);
      this.loans = [];
      this.searchedLoans = [];
      const request = {
        searchString: this.searchId,
        size: this.recentlyViewedLoanSize,
      };
      const loanSearch = this.graphqlApisService
        .loanSearch(request)
        .subscribe(({ data }: any) => {
          if (data?.loanSearch) {
            let searchedLoans = data?.loanSearch?.searchedLoan;
            const recentlyViewLoans = data?.loanSearch?.recentlyViewLoans;
            searchedLoans = searchedLoans?.filter(
              (loan: ILoan) => loan.displayLoanId
            );
            if (searchedLoans.length) {
              this.loans = recentlyViewLoans;
              this.searchedLoans = searchedLoans;
            } else {
              this.showNotFoundMessage = true;
            }
          }
          this.loadingCards = [];
        });
      this.subscription.add(loanSearch);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
