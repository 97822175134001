import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { CustomEventsService } from 'src/app/shared/services/custom-events/custom-events.service';
import { CustomEventTokens } from 'src/app/shared/models/custom-event-tokens';

import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import { TranslateService } from '@ngx-translate/core';

import { Subscription } from 'rxjs';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import navigationData from 'src/assets/json/navigation.json';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
})
export class NavigationComponent implements OnInit, OnDestroy {
  @Input('is-large-screen') public isLargeScreen = true;
  subscription: Subscription = new Subscription();
  userType!: string;
  navigationData!: any;
  constructor(
    private customEventsService: CustomEventsService,
    private i18nService: I18nService,
    public translate: TranslateService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.navigationData = JSON.parse(JSON.stringify(navigationData)).menuItems;
    let claims = this.localStorageService.getLocalStorageItem(
      CustomEventTokens.AGILITY_USER
    )?.claims;
    this.userType = claims.userType;
    let i18nServiceSubscription = this.i18nService
      .getLanguage()
      .subscribe((language: string) => {
        this.translate.use(language);
      });

    this.subscription.add(i18nServiceSubscription);
  }

  onMobileNavItemClick(): void {
    this.customEventsService.setEvent({
      name: CustomEventTokens.MOBILE_NAV_ITEM_CLICKED,
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
