import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiCallStatus } from 'src/app/shared/models/api-call-status.interface';
import { GraphqlApisService } from 'src/app/shared/services/graphql-apis/graphql-apis.service';
@Component({
  selector: 'app-global-loan-card',
  templateUrl: './global-loan-card.component.html',
  styleUrls: ['./global-loan-card.component.scss'],
})
export class GlobalLoanCardComponent implements OnInit {
  @Input('loan') loan!: any;
  @Input('index') index!: number;
  @Output() selectionChange = new EventEmitter<any>();

  @Input('data-loading') dataLoading: boolean = false;
  @Input('data-success') dataSuccess: boolean = false;

  softConditionCount!: number;
  hardConditionCount!: number;
  checked!: boolean;
  selectedValues!: any[];
  softConditions: Array<any> = [];
  hardConditions: Array<any> = [];
  subscription: Subscription = new Subscription();

  conditionsCallStatus!: ApiCallStatus;

  constructor(
    private datePipe: DatePipe,
    private graphqlApiService: GraphqlApisService
  ) {}

  ngOnInit(): void {
    if (
      this.loan?.status === 'New' ||
      this.loan?.status === 'Pending Approval'
    ) {
      this.getConditions(this.loan.fortaLoanId);
    }
  }
  get statusClass(): string {
    switch (this.loan?.status) {
      case 'New':
      case 'Processing':
        return 'color-success';
      case 'Returned':
        return 'color-danger';
      case 'Pending Approval':
        return 'color-warning';
      case 'Approved':
        return 'color-surface-300';
      case 'Funded':
        return 'color-success-dark';
      default:
        return '';
    }
  }
  get borrowerName(): string {
    const borrower = this.loan?.borrowers?.find(
      (borrower: any) => borrower.primary
    );
    switch (borrower?.borrowerType) {
      case 'Individual':
        return `${borrower.lastName}, ${borrower.firstName?.slice(0, 1)}.`;
      case 'Business':
        return borrower.businessName;
      case 'Trust':
        return borrower.trustName;
    }
    return `${borrower.lastName}, ${borrower.firstName?.slice(0, 1)}.`;
  }

  get requestedDate(): any {
    return this.getFormattedDate(this.loan.loanDetail.requestedWireDate);
  }

  selectionChanged(event: any) {
    this.selectionChange.emit({
      fortaLoanId: this.loan.fortaLoanId,
      checked: event.checked,
    });
  }
  getFormattedDate(dateString: string) {
    if (dateString == '') {
      return '';
    }
    const date = new Date(dateString);
    return this.datePipe.transform(date, 'MM/dd/yy');
  }
  checkboxId(index: number): string {
    return `select${index}`;
  }

  getConditions(loanId: any) {
    let getLoanConditionById = this.graphqlApiService
      .getLoanConditionsById(loanId)
      .subscribe((data: any) => {
        let conditions = data?.data?.getLoanFailedConditions;
        conditions?.forEach((item: any) => {
          if (item.softCondition === true && item.status === 'OPEN') {
            this.softConditions.push(item);
          }
          if (item.softCondition === false && item.status === 'OPEN') {
            this.hardConditions.push(item);
          }
        });
        this.softConditionCount = this.softConditions?.length;
        this.hardConditionCount = this.hardConditions?.length;
      });
    this.subscription.add(getLoanConditionById);
  }
}
