import { Injectable } from '@angular/core';
import { ApiCallStatus } from '../../models/api-call-status.interface';
import { ToastMessageService } from 'src/app/shared/services/toast-message.service';
import { GraphQLErrorExtensions, GraphQLError } from 'graphql';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  i: number = 0;

  constructor(private toastMessageService: ToastMessageService) {}

  inProgress(): ApiCallResponse {
    return new ApiCallResponse(true, false, false);
  }

  success(): ApiCallResponse {
    return new ApiCallResponse(false, true, false);
  }

  /**
   * @deprecated Should use error() instead
   */
  failed(message?: string): ApiCallResponse {
    this.toastMessageService.error(
      'user_message',
      Constants_API.error,
      'failed'
    );
    return new ApiCallResponse(false, false, true, message);
  }

  error(
    methodName: string,
    error: any,
    correlationId?: string
  ): ApiCallResponse {
    this.i++;
    if (Array.isArray(error)) {
      try {
        const gE = <GraphQLError>error[0];
        return this.errorGraphQL(methodName + '.g', gE);
      } catch (e) {
        //this should never happen.
        return this.errorGeneric(methodName + '.ge');
      }
    }
    if (error instanceof Error) {
      return this.errorTypeScript(methodName + '.e', error);
    }
    if (typeof error === 'string') {
      return this.errorGeneric(methodName + '.se', error, correlationId);
    } else {
      return this.errorGeneric(methodName + '.ne');
    }
  }

  errorGeneric(
    methodName: string,
    message?: string,
    _correlationId?: string
  ): ApiCallResponse {
    let showErrorMsg = `<b>Message:</b> ${Constants_API.error} </br> <b>CorrelationId:</b> ${_correlationId}`;
    let _message = environment.production
      ? showErrorMsg
      : message
      ? message
      : showErrorMsg;

    let response = new ApiCallResponse(false, false, true, _message);
    this.toastMessageService.error(
      methodName + this.i,
      _message,
      'Error in ' + methodName
    );
    return response;
  }

  errorTypeScript(methodName: string, e: Error): ApiCallResponse {
    const message = environment.production
      ? Constants_API.error
      : JSON.stringify(e.message);
    let response = new ApiCallResponse(false, false, true, message);
    const summary = 'Error TS';
    this.toastMessageService.error(methodName + this.i, message, summary);
    return response;
  }

  errorGraphQL(methodName: string, error: GraphQLError): ApiCallResponse {
    const message = environment.production
      ? Constants_API.error
      : JSON.stringify(error.message);
    let extensions: GraphQLErrorExtensions = error.extensions;
    let classification: string = extensions['classification']
      ? <string>extensions['classification']
      : '';
    let correlationId: string = extensions['correlationId']
      ? <string>extensions['correlationId']
      : '';
    let summary = 'Error';
    switch (classification.toLowerCase()) {
      case 'forbidden': {
        summary = 'Error: Access Denied';
        break;
      }
      case 'datafetchingexception': {
        summary = 'Error: Fetching Data';
        break;
      }
      case 'internal_error': {
        summary = 'Error: Internal Error';
        break;
      }
    }
    let response = new ApiCallResponse(false, false, true, message);
    this.toastMessageService.error(
      methodName + this.i,
      correlationId + '<br>' + message + '<br>@' + methodName,
      summary
    );
    return response;
  }

  createLoadingRows(rows: number): any[] {
    return Array.from({ length: rows }).map((_, i) => `Item #${i}`);
  }
}

export class ApiCallResponse implements ApiCallStatus {
  constructor(
    loading: boolean,
    success: boolean,
    error: boolean,
    errorMessage?: string
  ) {
    this.loading = loading;
    this.success = success;
    this.error = error;
    this.errorMessage = errorMessage;
  }
  loading: boolean;
  success: boolean;
  error: boolean;

  errorMessage?: string;

  //this is the real reason for the error...
  getErrorMessage(): string {
    return this.errorMessage ? this.errorMessage : '';
  }
}

export class Constants_API {
  public static error =
    'We encountered an error while retrieving this data. Please try again and if the problem persists please contact your account representative.';
}
