import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { PersistenceSettingsRequest } from '../../models/persistence-settings.interface';
import { AddOrUpdatePersistenceInput } from '../../models/add-or-update-persistence.interface';
import { Observable, map } from 'rxjs';

export interface PersistenceResult {
  data: {
    persistence: Persistence[];
  };
}

export interface Persistence {
  origin: string;
  originId: string;
  originType: string;
  tenantId: string;
  persistenceJson: string;
}

export interface PersistenceSaveResult {
  data: {
    addOrUpdatePersistence: Persistence;
  };
}

@Injectable({
  providedIn: 'root',
})
export class PersistenceGraphqlApisService {
  private GET_PERSISTENCE_SETTINGS = gql`
    query Persistence($persistenceSettingsRequest: PersistenceSettingsRequest) {
      persistence(persistenceSettingsRequest: $persistenceSettingsRequest) {
        tenantId
        origin
        originId
        originType
        persistenceJson
      }
    }
  `;

  private ADD_OR_UPDATE_PERSISTENCE = gql`
    mutation AddOrUpdatePersistence($input: AddOrUpdatePersistenceInput) {
      addOrUpdatePersistence(input: $input) {
        tenantId
        origin
        originId
        originType
        persistenceJson
      }
    }
  `;

  getPersistenceSettings(
    persistenceSettingsRequest: PersistenceSettingsRequest
  ): Observable<PersistenceResult> {
    return this.apollo
      .query({
        query: this.GET_PERSISTENCE_SETTINGS,
        variables: {
          persistenceSettingsRequest: persistenceSettingsRequest,
        },
      })
      .pipe(map((result) => result as PersistenceResult));
  }

  addOrUpdatePersistence(
    addOrUpdatePersistenceInput: AddOrUpdatePersistenceInput
  ): Observable<PersistenceSaveResult> {
    return this.apollo
      .mutate({
        mutation: this.ADD_OR_UPDATE_PERSISTENCE,
        variables: {
          input: addOrUpdatePersistenceInput,
        },
      })
      .pipe(map((result) => result as PersistenceSaveResult));
  }

  constructor(private apollo: Apollo) {}
}
