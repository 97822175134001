import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent implements OnInit {
  constructor(public router: Router, public auth: AuthService) {}

  ngOnInit(): void {
    this.auth.isAuthenticated$.subscribe((authenticated) => {
      if (!authenticated) {
        this.router.navigate(['/public']);
      }
    });
  }
}
