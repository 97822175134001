import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Confirmation,
  ConfirmationService,
  MessageService,
  PrimeNGConfig,
} from 'primeng/api';
import { Subscription, filter, map } from 'rxjs';
import { CustomEventTokens } from 'src/app/shared/models/custom-event-tokens';
import { CustomEvent } from 'src/app/shared/models/custom-event.interface';
import { CustomEventsService } from 'src/app/shared/services/custom-events/custom-events.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { ThemeService } from 'src/app/shared/services/theme/theme.service';
import { environment } from 'src/environments/environment';
import { PermissionService } from './shared/guards/permission/service/permission.service';
import { RouterUrlType } from './shared/models/routes-urls.type.enum';
import { User } from './shared/models/user.interface';
import { EnumerationsService } from './shared/services/enumerations/enumerations.service';
import { GraphqlApisService } from './shared/services/graphql-apis/graphql-apis.service';
import { I18nService } from './shared/services/i18n/i18n.service';
import { JwtService } from './shared/services/jwt.service';
import { ToastMessageService } from './shared/services/toast-message.service';
import { UserService } from './shared/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isCloseSidebar: boolean = false;
  currentLanguage!: string;
  subscription: Subscription = new Subscription();
  isAuthenticated: boolean = false;
  isFundingPipelinePage: boolean = false;
  isReportingPage: boolean = false;
  _showPermissionsBanner: boolean = false;
  isBannerMessage = false;
  currentTimeout: number = 0;
  confirmationObj: Confirmation = {
    header: 'Inactivity Timeout',
    message: '',
    acceptLabel: 'Continue',
    rejectLabel: 'Sign Out',
    defaultFocus: 'accept',
    rejectButtonStyleClass: 'p-button-outlined button-secondary',
    acceptIcon: 'pi',
    rejectIcon: 'pi',
    accept: () => {
      this.resetIdleState();
    },
    reject: (event: number) => {
      if (event === 1) {
        this.signOutFromInactiveConfirmationDialog();
      } else {
        this.resetIdleState();
      }
    },
    key: 'inactivityDialog',
  };

  constructor(
    private router: Router,
    private primengConfig: PrimeNGConfig,
    private toastMessageService: ToastMessageService,
    private customEventsService: CustomEventsService,
    private localStorageService: LocalStorageService,
    private themeService: ThemeService,
    private i18nService: I18nService,
    private translateService: TranslateService,
    private graphqlApisService: GraphqlApisService,
    private auth: AuthService,
    @Inject(DOCUMENT) private _document: any,
    private enumerationsService: EnumerationsService,
    private jwtService: JwtService,
    private userService: UserService,
    public permissionService: PermissionService,
    private messageService: MessageService,
    private idle: Idle,
    private cd: ChangeDetectorRef,
    private confirmationService: ConfirmationService
  ) {
    this.configureIdleSetup();
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;

    let translateServiceSubscription = this.translateService.onLangChange
      .pipe(map((evt) => evt.lang))
      .subscribe((lang) => (this._document.documentElement.lang = lang));

    let auth0AuthenticatedSubscription = this.auth.isAuthenticated$.subscribe(
      (authenticated) => {
        this.isAuthenticated = authenticated;
        if (!authenticated) {
          this.onLogout();
        } else {
          this.auth.idTokenClaims$.subscribe((idTokenClaims) => {
            if (idTokenClaims) {
              const agilityUser = this.localStorageService.getLocalStorageItem(
                CustomEventTokens.AGILITY_USER
              );

              agilityUser.claims =
                idTokenClaims?.['https://fortasolutions.com/user_metadata'];
              this.localStorageService.createLocalStorageItem(
                CustomEventTokens.AGILITY_USER,
                agilityUser
              );

              this.userService.initialize(agilityUser.claims);
              if (agilityUser.claims?.userType === 'SUB_TENANT') {
                let subTenantSubscription = this.graphqlApisService
                  .getOriginatorNameById(
                    agilityUser.claims?.subTenantId
                      ? agilityUser.claims?.subTenantId
                      : agilityUser.claims?.userId
                  )
                  .subscribe((result: any) => {
                    if (result?.data?.subTenant) {
                      const agilityUserLatest =
                        this.localStorageService.getLocalStorageItem(
                          CustomEventTokens.AGILITY_USER
                        );
                      agilityUserLatest.originatorName =
                        result.data.subTenant.originatorName;
                      this.localStorageService.createLocalStorageItem(
                        CustomEventTokens.AGILITY_USER,
                        agilityUserLatest
                      );
                    }
                  });
                this.subscription.add(subTenantSubscription);
              }
            }
          });

          this.auth
            .getAccessTokenSilently({ cacheMode: 'on' })
            .subscribe((token) => {
              const agilityUserLatest =
                this.localStorageService.getLocalStorageItem(
                  CustomEventTokens.AGILITY_USER
                );
              const claims = this.jwtService.DecodeToken(token);
              agilityUserLatest.permissions = claims?.['permissions'];
              this.localStorageService.createLocalStorageItem(
                CustomEventTokens.AGILITY_USER,
                agilityUserLatest
              );
            });

          this.getEnumerations();
          this.onLanguageChange();
          this.onLogin();
        }
      }
    );

    let userActionsServiceSubscription = this.customEventsService
      .getEvent()
      .subscribe((customEvent: CustomEvent) => {
        if (customEvent.name === CustomEventTokens.CHANGE_LANGUAGE) {
          this.onLanguageChange();
        } else if (
          customEvent.name === CustomEventTokens.CREATE_TENANT_SUCCESS
        ) {
          this.onCreateTenantSuccess();
        } else if (
          customEvent.name === CustomEventTokens.CREATE_IMB_PROFILE_SUCCESS
        ) {
          this.onImbProfileSuccess();
        } else if (
          customEvent.name === CustomEventTokens.UPDATE_MESSAGE_BANNER
        ) {
          this.getUserInfo();
        }
      });

    let i18nServiceSubscription = this.i18nService
      .getLanguage()
      .subscribe((language) => {
        this.currentLanguage = language;
      });

    let routerEventSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.isFundingPipelinePage = (event?.urlAfterRedirects).includes(
          'funding-pipeline'
        )
          ? true
          : false;

        this.isReportingPage = (event?.urlAfterRedirects).includes(
          'reporting/reporting'
        )
          ? true
          : false;
        this.checkPermissions(event?.urlAfterRedirects);
      });

    this.subscription.add(auth0AuthenticatedSubscription);
    this.subscription.add(userActionsServiceSubscription);
    this.subscription.add(i18nServiceSubscription);
    this.subscription.add(translateServiceSubscription);
    this.subscription.add(routerEventSubscription);
    this.showPermissionsBanner();

    this.resetIdleState();
  }

  onLogin(): void {
    this.toastMessageService.clear();
    this.getUserInfo();
  }

  onLogout(): void {
    this.stopIdle();
    this.localStorageService.deleteLocalStorageItem(
      CustomEventTokens.AGILITY_USER
    );
    this.themeService.renderTheme({});
    this.i18nService.setLanguage(CustomEventTokens.ENGLISH_LANGUAGE);
    this.auth.logout({
      logoutParams: { returnTo: this._document.location.origin },
    });
  }

  onCreateTenantSuccess(): void {
    this.toastMessageService.success('', 'Tenant Created Successfully');
  }
  onImbProfileSuccess(): void {
    this.toastMessageService.success('', 'Profile Created Successfully');
  }

  onLanguageChange(): void {
    if (this.currentLanguage !== undefined) {
      const user: User = this.getUser();
      user.language = this.currentLanguage;
      this.localStorageService.createLocalStorageItem(
        CustomEventTokens.AGILITY_USER,
        user
      );
      this.i18nService.saveLanguage(this.currentLanguage);
    }
  }

  getUser(): User {
    return this.localStorageService.getLocalStorageItem(
      CustomEventTokens.AGILITY_USER
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.stopIdle();
  }

  getEnumerations() {
    const enumerationsSubscription = this.graphqlApisService
      .getEnumerations()
      .subscribe(({ data }: any) => {
        if (data?.getEnumerations) {
          const user = this.localStorageService.getLocalStorageItem(
            CustomEventTokens.AGILITY_USER
          );
          user.enumerations = data?.getEnumerations;
          this.localStorageService.createLocalStorageItem(
            CustomEventTokens.AGILITY_USER,
            user
          );
          this.enumerationsService.updateEnumerations();
        }
      });

    this.subscription.add(enumerationsSubscription);
  }

  closeSidebar(event: any) {
    this.isCloseSidebar = event;
    this.userService.setIsCloseSidebar(this.isCloseSidebar);
  }

  checkPermissions(urlAfterRedirects: string) {
    let user: any = this.getUser();
    if (!user?.claims) return;
    let userType = user.claims.userType;
    if (urlAfterRedirects?.includes(RouterUrlType.FUNDING_PIPELINE)) {
      if (userType === 'SUB_TENANT') {
        this.setPermissionsBannerFlag([
          'ADMIN',
          'CREATE_LOAN',
          'EDIT_LOAN_DETAILS',
          'LOAN_REQUESTS',
        ]);
      }
      if (userType === 'TENANT') {
        this.setPermissionsBannerFlag(['ADMIN', 'LOAN_REQUESTS']);
      }
    } else if (
      urlAfterRedirects?.includes(RouterUrlType.FUNDING_LOAN_REQUEST)
    ) {
      if (userType === 'SUB_TENANT') {
        this.setPermissionsBannerFlag([
          'UPLOAD_LOAN_DOCS',
          'CREATE_LOAN',
          'DESIGNATE_HIGH_PRIORITY',
          'ADMIN',
          'VIEW_SENSITIVE_LOAN_DATA',
        ]);
      }
      if (userType === 'TENANT') {
        this.setPermissionsBannerFlag(['ADMIN', 'VIEW_SENSITIVE_LOAN_DATA']);
      }
    } else if (urlAfterRedirects?.includes(RouterUrlType.FUNDING_LOAN_VIEW)) {
      if (userType === 'SUB_TENANT') {
        this.setPermissionsBannerFlag([
          'ADMIN',
          'CREATE_LOAN',
          'LOAN_REQUESTS',
          'DESIGNATE_HIGH_PRIORITY',
          'ADJUST_ACCOUNTING_TRANSACTIONS',
          'INPUT_FEDREF_AT_LOAN_LEVEL',
          'EDIT_LOAN_DETAILS',
          'UPLOAD_LOAN_DOCS',
          'VIEW_SENSITIVE_LOAN_DATA',
          'REQUEST_ADDITIONAL_FUNDS',
        ]);
      }
      if (userType === 'TENANT') {
        this.setPermissionsBannerFlag([
          'ADMIN',
          'ADJUST_ACCOUNTING_TRANSACTIONS',
          'INPUT_FEDREF_AT_LOAN_LEVEL',
          'VIEW_SENSITIVE_LOAN_DATA',
          'OVERRIDE_FUNDING_CONDITIONS',
          'FUND_LOANS',
          'REQUEST_ADDITIONAL_FUNDS',
        ]);
      }
    } else if (urlAfterRedirects?.includes(RouterUrlType.COLLATERAL)) {
      if (userType === 'TENANT') {
        this.setPermissionsBannerFlag([
          'CHANGE_COLLATERAL_STATUS',
          'SHIP_COLLATERAL',
        ]);
      } else {
        this.permissionService.setShowPermissionsBanner(false);
      }
    } else if (urlAfterRedirects?.includes(RouterUrlType.DASHBOARD)) {
      let permissions: Array<string> = [];
      if (userType === 'TENANT') {
        permissions = [
          'FUNDING_DASHBOARD',
          'COLLATERAL_DASHBOARD',
          'MANAGEMENT_DASHBOARD',
          'EXECUTIVE_DASHBOARD',
        ];
      }
      if (userType === 'SUB_TENANT') {
        permissions = [
          'FUNDING_DASHBOARD',
          'COLLATERAL_DASHBOARD',
          'CREATE_LOAN',
          'ADMIN',
        ];
      }
      this.setPermissionsBannerFlag(permissions);
    } else if (
      urlAfterRedirects?.includes(RouterUrlType.ADMIN_GENERAL_SETUP) ||
      urlAfterRedirects?.includes(RouterUrlType.ADMIN_ORIGINATOR_PROFILE)
    ) {
      this.setPermissionsBannerFlag(['ADMIN']);
    } else if (
      urlAfterRedirects?.includes(RouterUrlType.ADMIN_FINANCING_SOURCE)
    ) {
      if (userType !== 'TENANT') {
        this.permissionService.setShowPermissionsBanner(false);
      } else {
        this.setPermissionsBannerFlag(['ADMIN']);
      }
    } else if (
      urlAfterRedirects?.includes(RouterUrlType.ADMIN_WIRE_RECIPIENT_PROFILE) ||
      urlAfterRedirects?.includes(RouterUrlType.ADMIN_WIRE_RECIPIENT_SETUP)
    ) {
      this.setPermissionsBannerFlag(['CREATE_EDIT_WIRE_RECIPIENT']);
    } else if (urlAfterRedirects?.includes(RouterUrlType.TREASURY)) {
      this.setPermissionsBannerFlag(['WITHDRAW_DEPOSIT']);
    } else if (
      urlAfterRedirects?.includes(RouterUrlType.ADMIN_SHIPPING) ||
      urlAfterRedirects?.includes(
        RouterUrlType.ADMIN_WIRE_RECIPIENT_VERIFICATION
      )
    ) {
      this.setPermissionsBannerFlag(['CONFIGURE_AGILITY_CONNECT']);
    } else if (urlAfterRedirects?.includes(RouterUrlType.ADMIN_CONDITIONS)) {
      this.setPermissionsBannerFlag([
        'OVERRIDE_FUNDING_CONDITIONS',
        'OVERRIDE_COLLATERAL_CONDITION',
        'OVERRIDE_SETTLEMENT_CONDITION',
        'OVERRIDE_SHIPPIG_CONDITION',
        'CONDITION_CONFIGURATION',
      ]);
    } else if (urlAfterRedirects?.includes(RouterUrlType.SETTLEMENT_LANDING)) {
      if (this.userService.isSubTenant) {
        this.setPermissionsBannerFlag([
          'ADD_LOANS_TO_WIRE',
          'SUBMIT_SETTLEMENT_REQUEST',
        ]);
      } else if (this.userService.isTenant) {
        this.setPermissionsBannerFlag([
          'MANAGE_SETTLEMENT_WIRES',
          'ADD_LOANS_TO_WIRE',
          'SETTLE_LOANS',
          'SUBMIT_SETTLEMENT_REQUEST',
        ]);
      }
    } else if (urlAfterRedirects?.includes(RouterUrlType.ORIGINATORS_SUMMARY)) {
      this.setPermissionsBannerFlag(['CREATE_EDIT_IMB_LINE_AND_PRODUCTS']);
    } else if (urlAfterRedirects?.includes(RouterUrlType.INDEX_SETUP)) {
      this.setPermissionsBannerFlag(['CREATE_EDIT_INDEX']);
    } else if (urlAfterRedirects?.includes(RouterUrlType.INVESTOR_SUMMARY)) {
      this.setPermissionsBannerFlag(['CREATE_EDIT_INVESTOR_DETAILS']);
    } else if (urlAfterRedirects?.includes(RouterUrlType.SUPER_ADMIN_TENANTS)) {
      this.setPermissionsBannerFlag(['SUPERUSER_ADMIN']);
    } else {
      this.permissionService.setShowPermissionsBanner(false);
    }
  }
  setPermissionsBannerFlag(permissions: string[]) {
    const flag = permissions.every((permission) =>
      this.permissionService.hasPermission({ permission: permission })
    );
    this.permissionService.setShowPermissionsBanner(!flag);
  }
  showPermissionsBanner() {
    const getShowPermissionsBannerSubscription = this.permissionService
      .getShowPermissionsBanner()
      .subscribe((flag: boolean) => {
        this._showPermissionsBanner = flag;
      });
    this.subscription.add(getShowPermissionsBannerSubscription);
  }
  onClosePermissionsBanner() {
    this.permissionService.setShowPermissionsBanner(false);
  }
  getUserInfo() {
    if (!this.userService.isSuperTenant) {
      const tenantSubscription = this.graphqlApisService
        .getUserInfo()
        .subscribe(({ data }: any) => {
          const isBannerMessageActive = data.getUserInfo.isBannerMessageActive;
          if (isBannerMessageActive) {
            this.messageService.clear();
            this.isBannerMessage = isBannerMessageActive;
            this.messageService.add({
              key: 'toast1',
              detail: data.getUserInfo.bannerMessage,
              sticky: true,
            });
          }
        });
      this.subscription.add(tenantSubscription);
    }
  }
  onCloseBannerMessage() {
    this.isBannerMessage = false;
  }

  initIdleSetup() {
    this.idle.setIdle(1680); //(28min*60sec) how long can user be inactive before considered idle, in seconds
    this.idle.setTimeout(120); //(2min*60sec) how long can user be idle before considered timed out, in seconds
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active
  }

  configureIdleSetup() {
    // set idle parameters
    this.initIdleSetup();

    // show inactivity confirmation dialog when the user becomes idle
    this.idle.onIdleStart.subscribe(() => {
      this.confirmationService.confirm(this.confirmationObj);
    });

    // reset the timeout when some activity happens when the dialog is shown
    this.idle.onIdleEnd.subscribe(() => {
      this.idle.setIdle(1);
      this.currentTimeout--;
      if (this.currentTimeout >= 1) {
        this.idle.setTimeout(this.currentTimeout);
      }
      this.idle.watch();
    });

    // sign out from the inactivity dialog when the user has timed out
    this.idle.onTimeout.subscribe(() => {
      this.signOutFromInactiveConfirmationDialog();
    });

    // update the time in the inactivity dialog when the timeout countdown does its thing
    this.idle.onTimeoutWarning.subscribe((seconds) => {
      this.currentTimeout = seconds;
      const timeout = this.formatInactivityTimeOut(seconds);
      this.confirmationObj.message = `Due to inactivity, you will be automatically signed out in ${timeout}. Click “Continue” to remain signed in.`;
      this.confirmationService.confirm(this.confirmationObj);
      this.cd.detectChanges();
    });
  }

  resetIdleState() {
    // start/reset the idle process and call idle.watch()
    this.initIdleSetup();
    this.idle.watch();
  }

  signOutFromInactiveConfirmationDialog() {
    this.confirmationService.close();
    this.onLogout();
  }

  formatInactivityTimeOut(totalSeconds: number): string {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString()}:${seconds.toString().padStart(2, '0')}`;
  }

  stopIdle() {
    this.idle.stop(); // Stop idle monitoring
    this.idle.clearInterrupts(); // Clear any interrupt signals
  }

  onHideInactivityDialog() {
    this.resetIdleState();
  }
}
