import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(value: string, _change: string, _with: string): unknown {
    if (!value || !_change || !_with)
      return;

    return value.replace(new RegExp(this.escapeStr(_change), 'g'), _with);;
  }
  escapeStr(str: string) {
    return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  }
}