import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CsvService {
  constructor() {}

  parse(stream: string): { header: Array<string>; data: Array<any> } {
    const propertyNames = stream.slice(0, stream.indexOf('\n')).split(',');
    const dataRows = stream.slice(stream.indexOf('\n') + 1).split('\n');
    let dataArray: any[] = [];
    dataRows.forEach((row: any) => {
      let values = row.split(',');
      let obj: any = new Object();
      for (let index = 0; index < propertyNames.length; index++) {
        const propertyName: string = propertyNames[index];
        let val: any = values[index];
        if (val === '') {
          val = undefined;
        }
        obj[propertyName?.replace(/(\r\n|\n|\r)/gm, '')] = val?.replace(
          /(\r\n|\n|\r)/gm,
          ''
        );
      }
      dataArray.push(obj);
    });
    return { header: propertyNames, data: dataArray };
  }
}
