import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private selectedIMBs: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  constructor() {}
  setSelectedIMBs(imbs: Array<any>) {
    this.selectedIMBs.next(imbs);
  }
  getSelectedIMBs(): BehaviorSubject<any> {
    return this.selectedIMBs;
  }
}
