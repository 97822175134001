import { Component, OnInit } from '@angular/core';
import { GraphqlApisService } from '../../services/graphql-apis/graphql-apis.service';
import { LoanSearchService } from '../../services/loan-search/loan-search.service';

@Component({
  selector: 'app-loan-search',
  templateUrl: './loan-search.component.html',
})
export class LoanSearchComponent implements OnInit {
  searchId!: string;
  constructor(
    private loanSearchService: LoanSearchService,
    private graphqlApisService: GraphqlApisService
  ) {}

  ngOnInit(): void {}

  onEnteringLoanNumber() {
    this.loanSearchService.setDisplayLoanId(this.searchId);
  }
}
