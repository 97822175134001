import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AgilityCurrencyComponent } from './agility-currency/agility-currency.component';
import { AgilityDateComponent } from './agility-date/agility-date.component';
import { AgilitySsnComponent } from './agility-ssn/agility-ssn.component';
import { AgilityEinComponent } from './agility-ein/agility-ein.component';
import { InputMaskModule } from 'primeng/inputmask';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { MultiSelectModule } from 'primeng/multiselect';
import { RouterModule } from '@angular/router';
import { SkeletonModule } from 'primeng/skeleton';
import { MessageModule } from 'primeng/message';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CustomEventTokens } from '../shared/models/custom-event-tokens';
import { HttpLoaderFactory } from '../shared/shared.module';
import { HttpClient } from '@angular/common/http';

@NgModule({
  declarations: [
    AgilityCurrencyComponent,
    AgilityDateComponent,
    AgilitySsnComponent,
    AgilityEinComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    InputMaskModule,
    ButtonModule,
    TooltipModule,
    TableModule,
    CardModule,
    MultiSelectModule,
    RouterModule,
    SkeletonModule,
    MessageModule,
    TranslateModule.forRoot({
      defaultLanguage: CustomEventTokens.ENGLISH_LANGUAGE,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    CommonModule,
    AgilityCurrencyComponent,
    AgilityDateComponent,
    AgilitySsnComponent,
    AgilityEinComponent,
    InputMaskModule,
    TooltipModule,
  ],
})
export class CustomModule {}
