<footer class="flex flex-wrap justify-content-end align-items-top">
  <nav class="mb-2">
    <ul class="nav justify-content-end">
      <li class="mr-4">
        <a
          href="https://www.fortasolutions.com/forta-terms-of-use/"
          target="_blank"
          class="underline"
          >{{ 'footer.termsOfUse' | translate }}</a
        >
      </li>
      <li class="mr-4">
        <a
          href="https://www.fortasolutions.com/forta-privacy-policy/"
          target="_blank"
          class="underline"
          >{{ 'footer.privacyPolicy' | translate }}</a
        >
      </li>
      <li class="mr-0 mr-4">
        <a href="mailto:{{ contactUsMail }}" class="underline">{{
          'footer.contactUs' | translate
        }}</a>
      </li>
    </ul>
  </nav>
  <p
    class="copyright m-0"
    [innerHTML]="'footer.copyright' | translate : { year: currentYear }"
  ></p>
</footer>
