import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Message } from 'primeng/api/message.d';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastMessageService {
  subscription: Subscription = new Subscription();
  constructor(private messageService: MessageService) {
    const messageServiceSubsription =
      this.messageService.messageObserver.subscribe(
        (msg: Message | Message[]) => {
          const type = Object.prototype.toString
            .call(msg)
            .slice(8)
            .slice(0, -1)
            .toLowerCase();
          if (type === 'object') {
            this.configureMessageTiming(msg as Message);
          }
          if (type === 'array') {
            (msg as Message[]).forEach((msg: Message) => {
              this.configureMessageTiming(msg as Message);
            });
          }
        }
      );
    this.subscription.add(messageServiceSubsription);
  }
  configureMessageTiming(msg: Message) {
    try {
      if (
        (msg as Message).severity == 'success' ||
        (msg as Message).severity == 'info'
      ) {
        (msg as Message).life = 1000;
      } else if ((msg as Message).severity == 'warn') {
        (msg as Message).life = 3000;
      } else if ((msg as Message).severity == 'error') {
        (msg as Message).sticky = true;
      }
    } catch (e) {
      (msg as Message).sticky = true;
    }
  }
  success(id: string, detail: string) {
    const msg: Message = {
      id,
      detail,
      icon: 'pi-check',
      severity: 'success',
      summary: 'Success',
      sticky: false,
      closable: true,
    };
    this.messageService.add(msg);
  }
  information(id: string, detail: string) {
    const msg: Message = {
      id,
      detail,
      icon: 'pi-info-circle',
      severity: 'info',
      summary: 'Info',
      sticky: false,
      closable: true,
    };
    this.messageService.add(msg);
  }
  warning(id: string, detail: string) {
    const msg: Message = {
      id,
      detail,
      icon: 'pi-exclamation-triangle',
      severity: 'warn',
      summary: 'Warning',
      sticky: false,
      closable: true,
    };
    this.messageService.add(msg);
  }
  error(id: string, detail: string, summary?: string) {
    const msg: Message = {
      id,
      detail,
      icon: 'pi-times-circle',
      severity: 'error',
      summary: summary ? summary : 'Error',
      sticky: true,
      closable: true,
    };
    this.messageService.add(msg);
  }
  locked(id: string, detail: string) {
    const msg: Message = {
      id,
      detail,
      icon: 'pi-lock',
      severity: 'warn',
      summary: 'Locked',
      sticky: false,
      closable: true,
    };
    this.messageService.add(msg);
  }
  emailSent(id: string, detail: string) {
    const msg: Message = {
      id,
      detail,
      icon: 'pi-send',
      severity: 'info',
      summary: 'Email Sent',
      sticky: false,
      closable: true,
    };
    this.messageService.add(msg);
  }
  deletedAccount(id: string, detail: string) {
    const msg: Message = {
      id,
      detail,
      icon: 'pi-trash',
      severity: 'success',
      summary: 'Deleted',
      sticky: false,
      closable: true,
    };
    this.messageService.add(msg);
  }
  clear() {
    this.messageService.clear();
  }
}
