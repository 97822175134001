import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'asterisk' })
export class AsteriskPipe implements PipeTransform {
  transform(value: number | string, lastCharacters: number = 5): string {
    let _value = String(value);
    if (_value.length > 0 && _value.length > lastCharacters) {
      return (
        _value.slice(0, _value.length - lastCharacters).replace(/[0-9]/g, '*') +
        _value.slice(-lastCharacters)
      );
    } else return _value;
  }
}
