<div class="p-inputgroup w-full px-2 flex flex-wrap pt-2 mb-2">
  <span class="p-input-icon-left flex-1">
    <i class="pi pi-search"></i>
    <input
      pInputText
      [(ngModel)]="searchId"
      placeholder="{{ 'loanSearch.enterLoan' | translate }}"
      mode="decimal"
      id="search-loan"
      inputStyleClass="pl-5"
      class="w-full h-full"
      (keyup.enter)="onEnteringLoanNumber()"
    />
  </span>
  <p-button styleClass="p-button-sm ml-1" (onClick)="onEnteringLoanNumber()">{{
    'loanSearch.go' | translate
  }}</p-button>
</div>
