import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable, map } from 'rxjs';


interface FundingWorkflowResponseResult {
  data: {
    fundingWorkflow: FundingWorkflowResponse[];
  };
}

interface FundingWorkflowResponse {
  status : string;
  highPriorityIndicator: boolean;
  count: number;
}

interface CollateralWorkflowResponseResult {
  data: {
    collateralWorkflow: CollateralWorkflowResponse[];
  };
}

interface CollateralWorkflowResponse {
  status : string;
  count: number;
}

interface SettlementWorkflowResponseResult {
  data: {
    settlementWorkflow: SettlementWorkflowResponse[];
  };
}

interface SettlementWorkflowResponse {
  fundsReceived : number;
  settlementRequest: number;
  unIdentified: number;
  returned: number;
  pendingConfirmation: number;
  subTenantIds: string[];
  tenantId: number;
}


@Injectable({
  providedIn: 'root',
})
export class WorkflowGraphqlApisService {

  constructor(private apollo: Apollo) { }

  private FUNDING_WORKFLOW = gql`
    query GetFundingWorkflow(
      $filter: WorkflowFilter!
    ) {
      fundingWorkflow(
        filter: $filter
      ) {
        status
        highPriorityIndicator
        count
      }
    }
  `;

  private COLLATERAL_WORKFLOW = gql`
      query GetCollateralWorkflow(
        $filter: WorkflowFilter
      ) {
        collateralWorkflow(
          filter: $filter
        ) {
          status
          count
        }
      }
    `;

  private SETTLEMENT_WORKFLOW = gql`
    query GetSettlementWorkflow(
      $filter: WorkflowFilter
    ) {
      settlementWorkflow(
        filter: $filter
      ) {
        fundsReceived
        settlementRequest
        unIdentified
        returned
        pendingConfirmation
        subTenantIds
        tenantId
      }
    }
  `;

  getFundingWorkflow(subtenantIds: string[]): Observable<FundingWorkflowResponseResult> {
    return this.apollo
    .query({
      query: this.FUNDING_WORKFLOW,
      variables: {
        filter: {
          subTenantIds: subtenantIds,
        },
      }
    }) .pipe(map((result) => result as FundingWorkflowResponseResult));
  }

  getCollateralWorkflow(subtenantIds: string[]): Observable<CollateralWorkflowResponseResult> {
    return this.apollo.query({
      query: this.COLLATERAL_WORKFLOW,
      variables: {
        filter: {
          subTenantIds: subtenantIds,
        },
      },
    }).pipe(map((result) => result as CollateralWorkflowResponseResult));
  }

  getSettlementWorkflow(subtenantIds: string[]): Observable<SettlementWorkflowResponseResult> {
    return this.apollo.query({
      query: this.SETTLEMENT_WORKFLOW,
      variables: {
        filter: {
          subTenantIds: subtenantIds,
        },
      },
    }).pipe(map((result) => result as SettlementWorkflowResponseResult));
  }

}