<div id="left-sidebar">
  <div *ngIf="!isAdminPage">
    <div *ngIf="!isHideSidebar">
      <div
        class="bg-slate-100 flex justify-content-end align-items-center mx-2 mb-2"
      >
        <p-multiSelect
          inputId="selectedSubTenants"
          *ngIf="isTenantUser"
          class="w-full m-2 mr-0 sidebar-multiselect"
          [options]="subTenants"
          defaultLabel="Select Originators"
          [(ngModel)]="selectedSubTenants"
          optionLabel="label"
          [maxSelectedLabels]="1"
          (onChange)="customCallbackFilter()"
        >
          <ng-template let-value pTemplate="selectedItems">
            <div *ngIf="selectedSubTenants.length === 0">
              Select Originators
            </div>
            <div *ngIf="selectedSubTenants.length === 1">
              {{ selectedSubTenants[0].label }}
            </div>
            <div
              *ngIf="
                selectedSubTenants.length > 1 &&
                selectedSubTenants.length < subTenants.length
              "
            >
              {{ selectedSubTenants.length }} Originators selected
            </div>
            <div
              *ngIf="
                selectedSubTenants.length === subTenants.length &&
                subTenants.length > 1
              "
            >
              All Originators
            </div>
          </ng-template>
        </p-multiSelect>
        <i
          class="bg-slate-100 cursor-pointer relative pi pi-bars p-2"
          (click)="closeSidebar()"
        ></i>
      </div>
      <app-loan-search *ngIf="isShowLoanSearch"></app-loan-search>
      <div class="px-2">
        <p-panelMenu [model]="sideBarMenuItems"></p-panelMenu>
      </div>
    </div>

    <div *ngIf="isHideSidebar">
      <i
        class="bg-slate-100 cursor-pointer relative pi pi-bars p-2"
        (click)="hideSidebar()"
      ></i>
      <div
        class="bg-white icon"
        [routerLink]="['/secure/funding']"
        routerLinkActive="active"
      >
        <i class="pi pi-money-bill text-primary cursor-pointer p-2"></i>
      </div>
      <div
        class="bg-white icon"
        [routerLink]="['/secure/collateral/collateral-pipeline']"
        routerLinkActive="active"
      >
        <i class="pi pi-send text-primary cursor-pointer p-2"></i>
      </div>
      <div
        class="bg-white icon"
        [routerLink]="['/secure/settlement']"
        routerLinkActive="active"
      >
        <i class="pi pi-dollar text-primary cursor-pointer p-2"></i>
      </div>
    </div>
  </div>
  <div *ngIf="isAdminPage">
    <div *ngIf="!isHideSidebar">
      <div class="bg-slate-100 flex align-items-center pl-4">
        <h4 class="adminText">{{ 'adminNavbar.header' | translate }}</h4>
        <i
          class="bg-slate-100 cursor-pointer relative pi pi-bars pi-bar"
          (click)="closeSidebar()"
        ></i>
      </div>
      <app-navbar [displaySideNavBar]="true" [isAgilityConnectExpanded]="true">
      </app-navbar>
    </div>
    <div *ngIf="isHideSidebar">
      <i
        class="bg-slate-100 cursor-pointer relative pi pi-bars p-2"
        (click)="hideSidebar()"
      ></i>
      <div class="sidebar-content"></div>
    </div>
  </div>
</div>
