<nav [ngClass]="{ 'ml-4 mt-4': !isLargeScreen }">
  <ul
    [id]="isLargeScreen ? 'appNav' : 'mobileAppNav'"
    class="nav"
    [class.justify-content-center]="isLargeScreen"
  >
    <ng-container *ngFor="let item of navigationData">
      <li *ngIf="item.path !== '#'" [class.mb-3]="!isLargeScreen">
        <a
          [routerLink]="item.path"
          (click)="!isLargeScreen && onMobileNavItemClick()"
          class="text-white no-underline"
          [class.mr-1]="isLargeScreen"
          routerLinkActive="active"
          ><i
            [class]="item.icon"
            [class.mr-1]="isLargeScreen"
            [class.mr-2]="!isLargeScreen"
          ></i
          >{{ item.title }}</a
        >
      </li>

      <li *ngIf="item.path === '#'" [class.mb-3]="!isLargeScreen">
        <a
          [href]="item.path"
          (click)="!isLargeScreen && onMobileNavItemClick()"
          class="text-white no-underline"
          [class.mr-1]="isLargeScreen"
          routerLinkActive="active"
          ><i
            [class]="item.icon"
            [class.mr-1]="isLargeScreen"
            [class.mr-2]="!isLargeScreen"
          ></i
          >{{ item.title }}</a
        >
      </li>
    </ng-container>
  </ul>
</nav>
