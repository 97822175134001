import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { CustomEventTokens } from 'src/app/shared/models/custom-event-tokens';

import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { CustomEventsService } from 'src/app/shared/services/custom-events/custom-events.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';

import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api/menuitem';
import { GraphqlApisService } from '../../services/graphql-apis/graphql-apis.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isMobileNavTurnedOn = false;
  isLargeScreen = false;
  private _getScreenWidth = 0;
  subscription: Subscription = new Subscription();
  currentLanguage = this.i18nService.getLanguage().value;
  items: MenuItem[] = [];
  userName?: string;
  name!: string | undefined;
  recentlyViewedLoanSize: number = 4;
  constructor(
    private customEventsService: CustomEventsService,
    private i18nService: I18nService,
    public translate: TranslateService,
    public auth: AuthService,
    public router: Router,
    public localStorage: LocalStorageService,
    private userService: UserService,
    private graphqlApisService: GraphqlApisService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit(): void {
    let claims = this.localStorage.getLocalStorageItem(
      CustomEventTokens.AGILITY_USER
    )?.claims;
    this.userName = claims?.fullName;

    if (this.userService.isSubTenant) {
      const getSubTenantSubScription = this.graphqlApisService
        .getSubTenantLegalName(this.userService.subTenantId)
        .subscribe((data: any) => {
          if (data?.data?.subTenant) {
            this.name = data?.data?.subTenant?.legalName;
          }
          this.getHeaderList();
        });
      this.subscription.add(getSubTenantSubScription);
    } else if (this.userService.isTenant) {
      const getTenantSubScription = this.graphqlApisService
        .getTenantName(this.userService.tenantId)
        .subscribe((data: any) => {
          if (data?.data?.getTenantByTenantId) {
            this.name = data?.data?.getTenantByTenantId.name;
          }
          this.getHeaderList();
        });
      this.subscription.add(getTenantSubScription);
    } else {
      this.name = this.userName;
      this.getHeaderList();
    }
    this.setScreenWidth(window.innerWidth);
    this.isLargeScreen = this.isScreenSizeLarge();

    let i18nServiceSubscription = this.i18nService
      .getLanguage()
      .subscribe((language: string) => {
        this.currentLanguage = language;
        this.translate.use(language);
      });

    this.subscription.add(i18nServiceSubscription);
  }
  getHeaderList() {
    this.items = [
      {
        label: `Hello, ${this.userName}`,
        title: this.userName,
        styleClass: 'user-name',
        disabled: true,
      },
      {
        label: this.name,
        title: this.name,
        styleClass: 'profile-tenant-name',
        disabled: true,
      },
      {
        label: 'View Profile',
        command: () => {
          this.router.navigate(['/secure/profile/view-profile']);
        },
      },
      {
        label: 'Sign Out',
        command: () => {
          this.logOut();
        },
      },
    ];
  }

  onEscKeyDown(): void {
    this.turnOffMobileNav();
  }

  toggleMobileNav(): void {
    this.isMobileNavTurnedOn = !this.isMobileNavTurnedOn;
  }

  turnOffMobileNav(): void {
    this.isMobileNavTurnedOn = false;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.setScreenWidth(window.innerWidth);
    this.isLargeScreen = this.isScreenSizeLarge();
    if (this.isLargeScreen) {
      this.isMobileNavTurnedOn = false;
    }
  }

  isScreenSizeLarge(): boolean {
    if (this.getScreenWidth() >= 1144) {
      return true;
    } else {
      return false;
    }
  }

  getScreenWidth(): number {
    return this._getScreenWidth;
  }

  setScreenWidth(width: number): void {
    this._getScreenWidth = width;
  }

  closeOverlayPanel($event: any, op: any) {
    op.toggle($event);
  }

  logOut(): void {
    this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin } });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
