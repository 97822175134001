import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage/local-storage.service';
import { CustomEventTokens } from '../models/custom-event-tokens';
import { UserType } from '../models/user-enumeration';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private sharedData = new BehaviorSubject<any>(null);
  sharedData$ = this.sharedData.asObservable();
  private _userType!: UserType;
  private _fullName!: string;
  private _claims!: any;
  private _subTenantId!: string;
  private _tenantId!: string;
  private _userId!: string;
  constructor(private localStorageService: LocalStorageService) {}

  initialize(claims: any) {
    this._claims = claims;
    this._userType = this._claims?.userType as UserType;
    this._fullName = this._claims?.fullName;
    this._tenantId =
      this.isSubTenant || this.isTenant ? this._claims?.tenantId : '';
    this._subTenantId = this.isSubTenant ? this.claims.subTenantId : '';
    this._userId = this._claims.userId;
  }
  get userType(): UserType {
    const _claims = this.claims;
    return _claims?.userType as UserType;
  }
  get isSuperTenant(): boolean {
    const _claims = this.claims;
    const _userType = _claims?.userType as UserType;
    return _userType === UserType.SUPER_TENANT;
  }
  get isTenant(): boolean {
    const _claims = this.claims;
    const _userType = _claims?.userType as UserType;
    return _userType === UserType.TENANT;
  }
  get isSubTenant(): boolean {
    const _claims = this.claims;
    const _userType = _claims?.userType as UserType;
    return _userType === UserType.SUB_TENANT;
  }
  get subTenantId(): string {
    const claims = this.claims;
    return this.isSubTenant
      ? claims.subTenantId != undefined
        ? claims.subTenantId
        : claims.userId
      : '';
  }
  get fullName(): string {
    return this._fullName;
  }
  get tenantId(): string {
    const claims = this.claims;
    return this.isTenant ? claims.tenantId : '';
  }
  get userId(): string {
    return this.claims.userId;
  }
  get claims(): any {
    return this.localStorageService.getLocalStorageItem(
      CustomEventTokens.AGILITY_USER
    )?.claims;
  }
  setIsCloseSidebar(data: boolean) {
    this.sharedData.next(data);
  }
}
